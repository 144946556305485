import React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const PpcVideoAd = withTheme(() => (
  <Global
    styles={css`
      .brand-logo {
        height: 50px;
      }

      @media (max-width: 940px) {
        .ppc-video-ad .module-area {
          max-height: 400px !important;
          margin-bottom: 48px !important;

          video {
            max-height: 400px !important;
          }
        }
      }

      @media (min-width: 940px) {
        .ppc-video-ad .module-area {
          padding-top: 0 !important;
          padding-right: 0 !important;
          padding-bottom: 0 !important;
          max-height: 1000px !important;

          video {
            max-height: 1000px !important;
          }
        }
      }
    `}
  />
))

export default PpcVideoAd
