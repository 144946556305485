import { css, Global, withTheme } from '@emotion/react'
import React from 'react'

const GlobalStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${theme?.colors?.dark?.base?.hex};
      }

      .clean-wrap {
        white-space: nowrap;
      }
    `}
  />
))

export default GlobalStyles
