import React from 'react'
import { withTheme } from '@emotion/react'
import TypographyStyles from './Typography.styles'
import PpcVideoAd from './PpcVideoAd.styles'
import PriceStyles from './Price.styles'

const GlobalStyles = withTheme(() => (
  <>
    <TypographyStyles />
    <PpcVideoAd />
    <PriceStyles />
  </>
))

export default GlobalStyles
