import React from 'react'
import {  withTheme, css, Global } from '@emotion/react'

const PriceStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-price-change {
        display: none !important;
      }
    `}
  />
))

export default PriceStyles
